<template>
  <div>
    <v-form
      v-model="valid"
      @submit.prevent="submitHandler"
      ref="addJobFutureForm"
    >
      <div
        :class="
          isMd ? 'text-center ' : 'd-flex align-center justify-space-between'
        "
      >
        <span
          class="d-block font-700 darkbord--text"
          :class="isMobile ? 'font-18 mb-2' : 'font-24 '"
          >{{ $t("dashboard.editFutureJob") }}</span
        >
        <dialog-btns
          v-if="isMobile"
          :topBtns="true"
          btnTitle="Save"
          closeTitle="Discard"
          @close="$emit('close')"
        ></dialog-btns>
      </div>
      <v-row class="mt-5">
        <!-- Job type -->
        <v-col cols="12">
          <base-auto-complete
            v-model="obj.job_type"
            :items="dataLists.jobs"
            :title="$t('forms.futureJobType')"
            :placeholder="$t('forms.futureJobType')"
          ></base-auto-complete>
        </v-col>

        <v-col cols="12">
          <base-auto-complete
            :items="countries"
            v-model="obj.DoctorRecommendedCountry"
            itemText="name"
            itemValue="id"
            :multiple="true"
            :title="$t('forms.doctorRecommendedCountry')"
            placeholder="Lebanon"
            @removeHandler="removeCountryHandler"
          ></base-auto-complete>
        </v-col>

        <!-- Country -->
        <v-col cols="12">
          <base-auto-complete
            :items="dataLists.countries"
            v-model="obj.country"
            :title="$t('forms.countryOfResidence')"
            :placeholder="$t('forms.countryOfResidence')"
          ></base-auto-complete>
        </v-col>

        <!-- Salary -->
        <v-col cols="12">
          <base-input
            type="number"
            v-model="obj.expected_salary_usd"
            :title="$t('forms.expectedSalary')"
            :placeholder="$t('forms.expectedSalary')"
            :rules="[]"
          ></base-input>
        </v-col>

        <!-- data check -->
        <v-col cols="12">
          <span class="darktext--text font-16 font-600 d-block"
            >{{ $t("forms.dataCheck") }}
            <span class="error--text">*</span>
          </span>
          <v-radio-group
            class="mt-1"
            :hide-details="true"
            v-model="obj.has_sign"
            mandatory
          >
            <v-radio
              :label="$t('forms.no')"
              value="NO"
              color="register-primary"
            ></v-radio>
            <v-radio
              :label="$t('forms.yes')"
              value="YES"
              color="register-primary"
            ></v-radio>
          </v-radio-group>
          <div class="upload-file" v-if="obj.has_sign == 'YES'">
            <base-auto-complete
              class="mt-5"
              :items="dataLists.countries"
              v-model="obj.data_check_country_id"
              :title="$t('forms.dataCheckCountry')"
              :placeholder="$t('forms.dataCheckCountry')"
            ></base-auto-complete>

            <base-file
              @input="fileHandler"
              class="mt-5 mb-2"
              v-model="datacheck"
              :isRequired="false"
              :isClear="true"
              :title="$t('forms.dataCheckFile')"
              :placeholder="$t('forms.dataCheckFile')"
              :rules="[(v) => !!v || '']"
            ></base-file>

            <!--                        -->
            <!--                        <v-file-input-->
            <!--                                :placeholder="$t('forms.uploadFile')"-->
            <!--                                prepend-icon=""-->
            <!--                                hide-details=""-->
            <!--                                v-model="datacheck"-->
            <!--                        ></v-file-input>-->
          </div>
          <a
            class="w-100 wrap-word"
            style="color: #38a894"
            v-if="typeof datacheck == 'string'"
            :href="datacheck"
            target="_blank"
            >{{ datacheck ? $t("forms.clickToView") : "" }}</a
          >
        </v-col>

        <!-- Country -->
        <v-col cols="12">
          <base-auto-complete
            :items="dataLists.about_us"
            v-model="obj.hear_about_us"
            :placeholder="$t('forms.howDidYouHead')"
            :title="$t('forms.howDidYouHead')"
            :rules="[]"
          ></base-auto-complete>
        </v-col>
      </v-row>

      <dialog-btns
        v-if="!isMobile"
        :btnTitle="
          completeProfile ? $t('forms.saveContinue') : $t('forms.save')
        "
        :closeTitle="completeProfile ? '' : $t('forms.discard')"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseAutoComplete from "../BaseAutoComplete.vue";
import BaseInput from "../BaseInput.vue";
import DialogBtns from "./DialogBtns.vue";
import BaseFile from "@/views/dashboard/components/BaseFile.vue";

export default {
  components: { BaseFile, DialogBtns, BaseInput, BaseAutoComplete },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    completeProfile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dataLists: {},
    values: [],
    obj: {},
    valid: false,
    countries: [],
    datacheck: [],
  }),
  computed: {
    ...mapGetters(["userType"]),
  },
  methods: {
    fileHandler(file) {
      this.datacheck = "";
      if (file.size > 2049892) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.fileSize"),
          color: "error",
        });
        return;
      } else {
        this.datacheck = file;
      }
    },
    removeCountryHandler(event) {
      const index = this.obj.DoctorRecommendedCountry.indexOf(event.id);
      if (index >= 0) this.obj.DoctorRecommendedCountry.splice(index, 1);
    },
    async submitHandler() {
      let valid = this.$refs.addJobFutureForm.validate();
      if (!valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth",
            });
        }, 100);
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      let url;
      if (this.userType == "doctor") {
        url = "update/additional/information";
      }
      let formData = new FormData();

      formData.append("job_type_id", this.obj.job_type);
      formData.append("country_id", this.obj.country);
      formData.append("has_sign", this.obj.has_sign == "YES" ? "1" : "0");
      if (this.obj.hear_about_us && this.obj.hear_about_us != null) {
        formData.append("hear_about_us_id", this.obj.hear_about_us);
      }
      if (this.obj.expected_salary_usd) {
        formData.append("expected_salary_usd", this.obj.expected_salary_usd);
      }

      this.obj.DoctorRecommendedCountry.forEach((item, idx) => {
        formData.append(`DoctorRecommendedCountry[${idx}]`, item);
      });
      if (this.datacheck) {
        formData.append(`datacheck`, this.datacheck);
        formData.append("data_check_country", this.obj.data_check_country_id);
      }

      let { data } = await this.$axios.post(url, formData, {
        headers: {
          Accept: "application/json",
        },
      });
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Updated Successfully",
          color: "success",
        });
        if (this.completeProfile) {
          this.$emit("nextHandler");
        }
        this.$emit("getData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async getDataLists() {
      let { data } = await this.$axios("information/data");
      if (data) {
        this.dataLists = data.data;
      }
    },
    async getCountries() {
      let { data } = await this.$axios.get("countries");
      if (data) {
        this.countries = data.data;
      }
    },
  },
  async created() {
    await this.getDataLists();
    await this.getCountries();
    this.obj = { ...this.info };
    this.obj.job_type = this.dataLists.jobs.filter(
      (item) => item.name == this.obj.job_type
    )[0].id;
    this.obj.country = this.dataLists.countries.filter(
      (item) => item.name == this.obj.country
    )[0].id;
    this.obj.hear_about_us = this.dataLists.about_us.filter(
      (item) => item.name == this.obj.hear_about_us
    )[0].id;
    this.obj.DoctorRecommendedCountry = this.info.future_job_location.map(
      (item) => item.id
    );
    if (this.info.has_sign) {
      this.obj.has_sign = "YES";
      this.datacheck = this.info.datacheck;
    }
  },
};
</script>

<style lang="scss">
.v-radio {
  label {
    font-size: 12px;
  }
}
</style>
